import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/layout";

const VideosPage = ({ data }) => (
  <Layout title="videos">
    <section>
      <div
        css={css`
          width: 1200px;
          max-width: 100%;
          margin: 0auto;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 20px;
          `}
        >
          {data.videos.nodes.map((video) => (
            <Link key={video.id} to={`/videos/${video.slug.current}`}>
              <BackgroundImage
                css={css`
                  height: 300px;
                `}
                fluid={video.thumbnail.childImageSharp.fluid}
              >
                <div
                  css={css`
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 0.3s all ease;
                    font-weight: bold;
                    &:hover {
                      background-color: black;
                      opacity: 80%;
                    }
                  `}
                >
                  {video.title}
                </div>
              </BackgroundImage>
            </Link>
          ))}
        </div>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
  query VideosPageQuery {
    videos: allSanityVideo {
      nodes {
        slug {
          current
        }
        videoId
        thumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        id
        title
      }
    }
  }
`;

export default VideosPage;
